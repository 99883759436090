import React from 'react';
import PropTypes from 'prop-types';

const AboutPageTemplate = ({ work, move }) => {
  return (
    <div className="about_page_template">
      <div className="container is-max-desktop">
        <div className="section">
          <div className="is-flex is-flex-direction-column">
            <h1 className="is-size-2 has-text-centered has-text-weight-bold is-uppercase has-text-black-bis mb-6">
              {work.title}
            </h1>
            <div className="mb-6 has-text-centered">
              <p>{work.description}</p>
            </div>
            <div className="is-flex is-align-items-center is-justify-content-center mb-6">
              <button className="bttn linked">
                <div className="hover-flip">
                  <span className="first">{work.about}</span>
                  <span className="second">{work.about}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="hero is-link ">
        <div className="">
          <div className="container">
            <div className="section m-4">
              <div className="is-flex is-align-content-center is-justify-content-space-between move-wrapper">
                <div className="is-flex is-flex-direction-column">
                  <h2 className="is-size-3 has-text-weight-light">
                    <b>{move.time}</b> {move.now}
                  </h2>
                  <div className="is-size-5">{move.need}</div>
                </div>
                <div className="is-flex buttons-wrapper">
                  <button className="bttn intro">
                    <div className="hover-flip">
                      <span className="first">{move.learn}</span>
                      <span className="second">{move.learn}</span>
                    </div>
                  </button>
                  <button className="bttn reverse">
                    <div className="hover-flip">
                      <span className="first">{move.bye}</span>
                      <span className="second">{move.bye}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  work: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    about: PropTypes.string,
  }),
  move: PropTypes.shape({
    time: PropTypes.string,
    now: PropTypes.string,
    need: PropTypes.string,
    bye: PropTypes.string,
    learn: PropTypes.string,
  }),
};

export default AboutPageTemplate;
