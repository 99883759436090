import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import AboutPageTemplate from '../components/about/AboutTemplate';

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        work={post.frontmatter.work}
        move={post.frontmatter.move}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        work {
          title
          description
          about
        }
        move {
          time
          now
          need
          bye
          learn
        }
      }
    }
  }
`;

export default AboutPage;
